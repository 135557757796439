$(function(){
  function sideForm(){
    $('.side-form').toggleClass('side-form_opened');
    $('.side-form__btn-content_close, .side-form__btn-content_open').toggleClass('side-form__btn-content_hide');
    $('.side-form__overlay').toggleClass('side-form__overlay_revealed');
  }

  $('.side-form__btn, .side-form__overlay').click(sideForm);

  $('body').keyup(function(e){
    if ($('.side-form_opened').length && e.which == 27){
      sideForm();
    }
  });


  $('#simply-form').validator().on('submit', function (e) {
    $('#db_send').removeClass("banner-form-hidden-spinner");
    if (e.isDefaultPrevented()) {
      $('#db_send').addClass("banner-form-hidden-spinner");
      return false;
    }else{

      $('#db_send').attr('disabled',true);
      $.ajax({
        type: "POST",
        url: $('#simply-form').attr('action'),
        data: $('#simply-form').serialize(),
        dataType: 'html',
        success: function(){
          $('#simply-form .msg-success').removeClass('hidden');
          $('#simply-form input, #simply-form textarea').each(function(){
            $(this).val("");
          });
          $('#db_send').attr('disabled',false);
          $('#db_send').addClass("banner-form-hidden-spinner");
          setTimeout(function() {
            $('#simply-form .msg-success').addClass('hidden');
          }, 3000);
        }

      });
      return false;
    }
    return false;
  });
  $('#db_send').on('click',function(){
    if($(this).hasClass("banner-form-hidden-spinner")){
      $(this).removeClass("banner-form-hidden-spinner");
      return true;
    }
    return true;
  });


  function isIEorEDGE(){
    if (navigator.appName == 'Microsoft Internet Explorer'){
      return true; // IE
    }
    else if(navigator.appName == "Netscape"){
       return navigator.appVersion.indexOf('Edge') > -1; // EDGE
    }

    return false;
  }

  // if(isIEorEDGE()){
  //   $('.side-form__btn').addClass('side-form__btn--edge');
  // }

});
