$(document).ready(function() {
	$('#hp-proj').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		autoplaySpeed: 3000,
		speed: 2000,
		rtl: ($("html").attr('dir') == 'rtl' ? true : false),
		autoplay: true,
		infinite: true,
		responsive: [{
			breakpoint: 765,
			settings: {
				slidesToShow: 2,
			}
		},
		{
			breakpoint: 560,
			settings: {
				slidesToShow: 1,
			}
		}]
	});

	$('#hp-proj').lightGallery({
		thumbnail: true,
		selector: '.proj-box__img'
	});

});

// responsive: [{
// 	: 1100,
// 	settings: {
// 		slidesToShow: 5,
// 		slidesToScroll: 1
// 	}
// },
// {
// 	breakpoint: 993,
// 	settings: {
// 		slidesToShow: 4,
// 		slidesToScroll: 1
// 	}
// },
// {
// 	breakpoint: 765,
// 	settings: {
// 		slidesToShow: 3,
// 		slidesToScroll: 1
// 	}
// },
// {
// 	breakpoint: 600,
// 	settings: {
// 		slidesToShow: 3,
// 		slidesToScroll: 1
// 	}
// },
// {
// 	breakpoint: 550,
// 	settings: {
// 		slidesToShow: 2,
// 		slidesToScroll: 1
// 	}
// },
// {
// 	breakpoint: 415,
// 	settings: {
// 		slidesToShow: 1,
// 		slidesToScroll: 1
// 	}
// }
// ]